import { toSignal } from '@angular/core/rxjs-interop';
import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, mergeMap, tap, throwError } from 'rxjs';
import {
  BuyGiftCardsClientModel,
  GetMeGiftCardsModels,
  GiftCardsModels,
  GiftCardsTemplateModels,
  ValidateGiftCardModels,
} from '../models';
import {
  BuyGiftCardsAction,
  GiftCardsAction,
  GiftCardsFailureAction,
  GiftCardsTemplatesAction,
} from './gift-cards.actions';
import { GiftCardsService } from '../services/gift-cards.service';
import { TableData } from '@carwash-project/modules/ui';
import { skipToPage } from '@carwash-project/modules/utils';
import {
  CachingModel,
  QueryModel,
} from '@carwash-project/modules/data-access/common';
import { AuthState } from '@carwash-project/modules/data-access/auth';

export interface GiftCardsStateModel {
  giftCards: GiftCardsModels.List | null;
  giftCardCaching: CachingModel<GiftCardsModels.List>[];
  giftCardsMe: GetMeGiftCardsModels.List | null;
  buyResult: BuyGiftCardsClientModel.Response | null;
  validation: ValidateGiftCardModels.Response | null;
  filtersGiftCards: GiftCardsModels.Queries | null;
  templates: GiftCardsTemplateModels.List | null;
  loading: boolean;
  loadingCreateGiftCards: boolean;
  error: unknown;
}

const initialState: GiftCardsStateModel = {
  giftCards: null,
  giftCardCaching: [],
  giftCardsMe: null,
  buyResult: null,
  filtersGiftCards: null,
  validation: null,
  templates: null,
  loading: false,
  loadingCreateGiftCards: false,
  error: null,
};

@State<GiftCardsStateModel>({
  name: 'giftCards',
  defaults: initialState,
})
@Injectable()
export class GiftCardsState {
  private readonly store = inject(Store);
  private readonly isAuth = toSignal(this.store.select(
    AuthState.isAuthenticated
  ))

  private readonly giftCardsService = inject(GiftCardsService);

  @Selector()
  public static giftCards(state: GiftCardsStateModel) {
    return state.giftCards;
  }

  @Selector()
  public static giftCardsMe(state: GiftCardsStateModel) {
    return state.giftCardsMe;
  }

  @Selector()
  public static validation(state: GiftCardsStateModel) {
    return state.validation;
  }

  @Selector()
  public static templates(state: GiftCardsStateModel) {
    return state.templates;
  }

  @Selector()
  public static templatesTable(state: GiftCardsStateModel) {
    return new TableData(
      state.templates?.results ?? [],
      state.templates?.totalCount
    );
  }

  @Selector()
  public static giftCardsTable(state: GiftCardsStateModel) {
    return new TableData(
      state.giftCards?.results ?? [],
      state.giftCards?.totalCount
    );
  }

  @Selector()
  public static loadingCreateGiftCards(state: GiftCardsStateModel) {
    return state.loadingCreateGiftCards;
  }

  @Selector()
  public static loading(state: GiftCardsStateModel) {
    return state.loading;
  }

  @Selector()
  public static buyResult(state: GiftCardsStateModel) {
    return state.buyResult;
  }

  private transformData(
    data: GiftCardsTemplateModels.List
  ): GiftCardsTemplateModels.List {
    return {
      ...data,
      results: data.results.map((item) => ({
        ...item,
        limitWashes_display: item.limitWashes ?? 'N/A',
        limitDays_display: item.limitDays ?? 'N/A',
        limitWashesByDay_display: item.limitWashesByDay ?? 'N/A',
      })),
    };
  }

  private findCaching(
    ctx: StateContext<GiftCardsStateModel>,
    queries: QueryModel | null
  ) {
    if (
      queries &&
      typeof queries.skip === 'number' &&
      typeof queries.limit === 'number'
    ) {
      const caching = ctx.getState().giftCardCaching;

      return caching
        ? caching.find(
            (item) =>
              item.page === skipToPage(queries.skip!, queries.limit) &&
              item.limit === queries.limit
          )
        : null;
    }

    return null;
  }

  /** Action Handle Error */
  @Action(GiftCardsFailureAction)
  public handleError(
    ctx: StateContext<GiftCardsStateModel>,
    { error }: GiftCardsFailureAction
  ) {
    ctx.patchState({
      error,
      loading: false,
      loadingCreateGiftCards: false,
    });
    return throwError(() => error);
  }

  @Action(GiftCardsAction.ClearCaching)
  public clearCaching(ctx: StateContext<GiftCardsStateModel>) {
    ctx.patchState({
      giftCardCaching: [],
    });
  }

  @Action(GiftCardsAction.GetAll)
  public getAllGiftCards(
    ctx: StateContext<GiftCardsStateModel>,
    { queries, enabledCaching }: GiftCardsAction.GetAll
  ) {
    if (!this.isAuth()) {
      return;
    }
    ctx.patchState({ loading: true, filtersGiftCards: queries });

    const findCaching = this.findCaching(ctx, queries);

    if (enabledCaching && findCaching) {
      ctx.patchState({
        giftCards: findCaching.data,
        loading: false,
      });

      return;
    }

    return this.giftCardsService.getAllGiftCards(queries).pipe(
      tap((payload) => {
        const caching = ctx.getState().giftCardCaching;

        ctx.patchState({
          giftCards: payload,
          loading: false,
        });

        if (
          enabledCaching &&
          queries &&
          typeof queries.skip === 'number' &&
          typeof queries.limit === 'number'
        ) {
          ctx.patchState({
            giftCardCaching: caching
              ? caching.concat({
                  page: skipToPage(queries.skip, queries.limit),
                  limit: queries.limit,
                  data: payload,
                })
              : [],
          });
        }
      }),
      catchError((error) => ctx.dispatch(new GiftCardsFailureAction(error)))
    );
  }

  @Action(GiftCardsAction.Create)
  public createGiftCards(
    ctx: StateContext<GiftCardsStateModel>,
    { body }: GiftCardsAction.Create
  ) {
    ctx.patchState({ loadingCreateGiftCards: true });
    return this.giftCardsService.createGiftCards(body).pipe(
      tap(() => {
        ctx.patchState({
          loadingCreateGiftCards: false,
        });
      }),
      mergeMap(() =>
        ctx.dispatch(
          new GiftCardsAction.GetAll(ctx.getState().filtersGiftCards, false)
        )
      ),
      catchError((error) => ctx.dispatch(new GiftCardsFailureAction(error)))
    );
  }

  @Action(GiftCardsTemplatesAction.GetAll)
  public getTemplates(
    ctx: StateContext<GiftCardsStateModel>,
    { queries }: GiftCardsTemplatesAction.GetAll
  ) {
    ctx.patchState({ loading: true });
    return this.giftCardsService.getTemplates(queries).pipe(
      tap((payload) => {
        ctx.patchState({
          templates: this.transformData(payload),
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new GiftCardsFailureAction(error)))
    );
  }

  @Action(GiftCardsTemplatesAction.Create)
  public createTemplates(
    ctx: StateContext<GiftCardsStateModel>,
    { body }: GiftCardsTemplatesAction.Create
  ) {
    ctx.patchState({ loading: true });
    return this.giftCardsService.createTemplates(body).pipe(
      tap(() => {
        ctx.patchState({
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new GiftCardsFailureAction(error)))
    );
  }

  @Action(GiftCardsAction.Validation)
  public gitCardValidation(
    ctx: StateContext<GiftCardsStateModel>,
    { body }: GiftCardsAction.Validation
  ) {
    ctx.patchState({ loading: true });
    return this.giftCardsService.validation(body).pipe(
      tap((payload) => {
        ctx.patchState({
          validation: payload,
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new GiftCardsFailureAction(error)))
    );
  }

  @Action(GiftCardsAction.Send)
  public sendGiftCard(
    ctx: StateContext<GiftCardsStateModel>,
    { body }: GiftCardsAction.Send
  ) {
    ctx.patchState({ loading: true });
    return this.giftCardsService.giftCardSend(body).pipe(
      tap(() => {
        ctx.patchState({
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new GiftCardsFailureAction(error)))
    );
  }

  @Action(GiftCardsAction.GetAllMe)
  public getMeGiftCards(
    ctx: StateContext<GiftCardsStateModel>,
    { queries }: GiftCardsAction.GetAllMe
  ) {
    ctx.patchState({ loading: true });
    return this.giftCardsService.getMeGiftCards(queries).pipe(
      tap((payload) => {
        ctx.patchState({
          giftCardsMe: payload,
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new GiftCardsFailureAction(error)))
    );
  }

  @Action(BuyGiftCardsAction.ByClient)
  public buyGiftCardByClient(
    ctx: StateContext<GiftCardsStateModel>,
    { body }: BuyGiftCardsAction.ByClient
  ) {
    ctx.patchState({ loading: true });
    return this.giftCardsService.buyGiftCardByClient(body).pipe(
      tap((payload) => {
        ctx.patchState({
          buyResult: payload,
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new GiftCardsFailureAction(error)))
    );
  }

  @Action(BuyGiftCardsAction.ByPublic)
  public buyGiftCardByPublic(
    ctx: StateContext<GiftCardsStateModel>,
    { body }: BuyGiftCardsAction.ByPublic
  ) {
    ctx.patchState({ loading: true });
    return this.giftCardsService.buyGiftCardPublic(body).pipe(
      tap((payload) => {
        ctx.patchState({
          buyResult: payload,
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new GiftCardsFailureAction(error)))
    );
  }
}
