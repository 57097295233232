import { Injectable, inject } from '@angular/core';
import { BASE_URL_API, HttpService } from '@carwash-project/modules/core';
import { Observable } from 'rxjs';
import {
  BuyGiftCardPublicModel,
  BuyGiftCardsClientModel,
  GetMeGiftCardsModels,
  GiftCardSendModel,
  GiftCardsModels,
  GiftCardsTemplateModels,
  ValidateGiftCardModels,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class GiftCardsService {
  private readonly baseApi: string = inject(BASE_URL_API);
  private readonly httpService = inject(HttpService);
  private readonly mainGiftCardsPath = `${this.baseApi}giftcards`;
  private readonly mainTemplatesPath = `${this.baseApi}giftcardtemplate`;
  private readonly mainPurchases = `${this.baseApi}purchases`;

  public getAllGiftCards(
    queries: GiftCardsModels.Queries | null
  ): Observable<GiftCardsModels.List> {
    return this.httpService.get(
      this.mainGiftCardsPath,
      queries,
      'Error al cargar las gift cards.'
    );
  }

  public createGiftCards(
    body: GiftCardsModels.Create
  ): Observable<{ customMessage: string }> {
    return this.httpService.post(
      this.mainGiftCardsPath,
      body,
      'Error al crear las gift cards.'
    );
  }

  public getTemplates(
    queries: GiftCardsTemplateModels.Queries
  ): Observable<GiftCardsTemplateModels.List> {
    return this.httpService.get(
      this.mainTemplatesPath,
      queries,
      'Error al listar los templates.'
    );
  }

  public createTemplates(
    body: GiftCardsTemplateModels.Create
  ): Observable<GiftCardsTemplateModels.Template> {
    return this.httpService.post(
      this.mainTemplatesPath,
      body,
      'Error al crear el template.'
    );
  }

  public validation(
    body: ValidateGiftCardModels.Body
  ): Observable<ValidateGiftCardModels.Response> {
    const url = `${this.mainGiftCardsPath}/validate`;
    return this.httpService.post(url, body, 'Error al validar gift cards.');
  }

  public getMeGiftCards(
    filters: GetMeGiftCardsModels.Filters | null
  ): Observable<GetMeGiftCardsModels.List> {
    const url = `${this.mainGiftCardsPath}/me`;
    return this.httpService.get(
      url,
      filters,
      'Error al listar las gift cards.'
    );
  }

  public giftCardSend(
    body: GiftCardSendModel
  ): Observable<{ message: string }> {
    const url = `${this.mainGiftCardsPath}/send`;
    return this.httpService.post(url, body, 'Error al enviar gift card.');
  }

  public giftCardDetail(
    code: string
  ): Observable<GetMeGiftCardsModels.GiftCards> {
    const url = `${this.mainGiftCardsPath}/${code}`;
    return this.httpService.get(url, null, 'Error al consultar la gift card.');
  }

  public buyGiftCardByClient(
    body: BuyGiftCardsClientModel.Body
  ): Observable<BuyGiftCardsClientModel.Response> {
    return this.httpService.post(
      this.mainPurchases,
      body,
      'Error al comprar gift cards.'
    );
  }

  public buyGiftCardPublic(
    body: BuyGiftCardPublicModel.Body
  ): Observable<BuyGiftCardPublicModel.Response> {
    return this.httpService.post(
      this.mainPurchases,
      body,
      'Error al comprar gift cards.'
    );
  }
}
