import {
  BuyGiftCardPublicModel,
  BuyGiftCardsClientModel,
  GetMeGiftCardsModels,
  GiftCardSendModel,
  GiftCardsModels,
  GiftCardsTemplateModels,
  ValidateGiftCardModels,
} from '../models';

export namespace GiftCardsAction {
  export class GetAll {
    static readonly type = '[GiftCards] Get all gift cards';
    constructor(
      public queries: GiftCardsModels.Queries | null,
      public enabledCaching: boolean = true
    ) {}
  }

  export class GetAllMe {
    static readonly type = '[GiftCards] Get all me gift cards';
    constructor(public queries: GetMeGiftCardsModels.Filters | null) {}
  }

  export class Validation {
    static readonly type = '[GiftCards] Validation gift cards';
    constructor(public body: ValidateGiftCardModels.Body) {}
  }

  export class Send {
    static readonly type = '[GiftCards] Send gift cards';
    constructor(public body: GiftCardSendModel) {}
  }

  export class Create {
    static readonly type = '[GiftCards] Create gift cards';
    constructor(public body: GiftCardsModels.Create) {}
  }

  export class ClearCaching {
    static readonly type = '[GiftCards] Clear gift cards caching';
  }
}

export namespace GiftCardsTemplatesAction {
  export class GetAll {
    static readonly type = '[GiftCards] Get all templates gift cards';
    constructor(public queries: GiftCardsTemplateModels.Queries) {}
  }

  export class Create<T = string> {
    static readonly type = '[GiftCards] Create templates gift cards';
    constructor(public body: GiftCardsTemplateModels.Create<T>) {}
  }
}

export namespace BuyGiftCardsAction {
  export class ByClient {
    static readonly type = '[GiftCards] Buy gift card by client.';
    constructor(public body: BuyGiftCardsClientModel.Body) {}
  }

  export class ByPublic {
    static readonly type = '[GiftCards] Buy gift card by public.';
    constructor(public body: BuyGiftCardPublicModel.Body) {}
  }
}

export class GiftCardsFailureAction {
  static readonly type = '[GiftCards] Load Error';
  constructor(public error: unknown) {}
}
